<div class="inner-view models-groups-view">
  <div class="view-header align-container space-between">
    <h1 class="view-header align-container small-spacing">
      <span>Models Groups</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section filtering-panel align-container space-between">
    <div class="filtering-block align-container">
      <div class="search-model-group">
        <app-search-bar [(ngModel)]="query" (search)="search($event)" [searching]="loading" placeholder="Search by name or device type"></app-search-bar>
      </div>
      <div class="status-block align-container mid-spacing">
        <div class="label">Status:</div>
        <div class="status-filter">
          <app-dropdown [items]="statuses" [(ngModel)]="status" placeholder="Select status" (ngModelChange)="updateData(true)"></app-dropdown>
        </div>
      </div>
      <div class="range-select filter-item align-container mid-spacing">
        <span class="label">Created period:</span>
        <app-dates-range [(ngModel)]="range" (ngModelChange)="updateData(true)" [typeSelector]="false" [clearable]="true">
        </app-dates-range>
      </div>
    </div>
    <div class="controls">
      <button class="primary button-add" (click)="createModelsGroup()">Add New</button>
    </div>
  </div>
  <div class="view-section results-block">
    <perfect-scrollbar>
      <table class="results-table">
        <thead>
          <tr>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.GROUP_NAME" (sort)="setSorting($event)">
                Group Name
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.DEVICE_TYPE" (sort)="setSorting($event)">
                Device Type
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.STATUS" (sort)="setSorting($event)">
                Status
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.CREATED" (sort)="setSorting($event)">
                Created
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.UPDATED" (sort)="setSorting($event)">
                Updated
              </app-sortable-header>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of modelsGroups?.content">
            <td class="break-word">{{row.name || '&mdash;'}}</td>
            <td>{{row.deviceType?.name || '&mdash;'}}</td>
            <td>
              <div class="capitalize" [class.activity-status]="!!row.status"
                  [class.active]="row.status === DeviceModelGroupStatus.ACTIVE"
                  [class.inactive]="row.status === DeviceModelGroupStatus.INACTIVE">
                {{row.status || '&mdash;'}}
              </div>
            </td>
            <td>{{row.createdAt ? (row.createdAt | date:DATE_FORMAT) : '&mdash;'}}</td>
            <td>{{row.updatedAt ? (row.updatedAt | date:DATE_FORMAT) : '&mdash;'}}</td>
            <td><app-actions [actions]="row.actions"></app-actions></td>
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!modelsGroups || !modelsGroups?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="modelsGroups && modelsGroups.totalPages > 1">
      <div class="text-info">Showing {{modelsGroups.startElement}} to {{modelsGroups.endElement}} of {{modelsGroups.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="modelsGroups.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
  <div><b>Models Groups</b> – section that allows grouping models that have the same functionality but may differ only in non-functional features (such as color, device dimensions, etc.). As a rule, models in a model group have the same Device JSON-config. Model groups can be used in various mass operations, for example, to load test reports for models that have the same functional characteristics.</div>
  <div class="less-space"><b>Conditions:</b></div>
  <ul class="min-space">
    <li>A model group contains at least one model.</li>
    <li>One model can be in more than one group.</li>
  </ul>
  <div class="less-space">In this section you can:</div>
  <ol class="min-space">
    <li>Add a new model group</li>
    <li>Edit an existing model group</li>
    <li>Delete a model group</li>
  </ol>
  <div class="less-space">The main window of the section contains a list of groups of models with information about:</div>
  <ol class="min-space">
    <li><b>GROUP NAME</b> – model group name</li>
    <li><b>DEVICE TYPE</b> – the model type within which the model group is contained. A model group can only be assigned to devices of the same type  (e.g. only to the Washing Machine type)</li>
    <li><b>STATUS</b> – models group status (Active/ Inactive)</li>
    <li><b>CREATED</b> – model group creation date</li>
    <li><b>UPDATED</b> – model group update date</li>
  </ol>
</ng-template>
