import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceModelGroupStatus, DeviceModelsGroup, DeviceModelsGroupView } from '@models/device-models';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { DeviceModelsService } from '@services/device-models.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogIcon } from '@models/dialogs';
import { DATE_TIME } from '@constants/dates';
import { Paged } from '@models/pageable';
import { Sorting, SortOrder } from '@models/sorting';
import { finalize, Subject, takeUntil } from 'rxjs';
import { TestReportStatus } from "@models/report";
import { DropdownItem } from "@models/dropdown";
import { Utils } from "@services/utils";
import { DatesRange } from "@models/dates";

enum SortColumn {
  GROUP_NAME = 'name',
  DEVICE_TYPE = 'deviceTypeName',
  STATUS = 'status',
  CREATED = 'createdAt',
  UPDATED = 'updatedAt'
}

@Component({
  selector: 'app-models-groups-view',
  templateUrl: './models-groups-view.component.html',
  styleUrls: ['./models-groups-view.component.scss']
})
export class ModelsGroupsViewComponent implements OnInit, OnDestroy {

  public modelsGroups: Paged<DeviceModelsGroupView>;
  public activePage = 1;
  public loading = false;
  public query = '';
  public statuses: Array<DropdownItem> = [];
  public status: DropdownItem;
  public range: DatesRange;
  public DATE_FORMAT = DATE_TIME;
  public sorting: Sorting = {column: SortColumn.CREATED, order: SortOrder.DESC};
  public SortColumn = SortColumn;
  public DeviceModelGroupStatus = DeviceModelGroupStatus;

  @ViewChild('information') information: TemplateRef<any>;
  private destroyed = new Subject<void>();

  constructor(private dialogService: DialogService, private notificationService: NotificationService,
    private deviceModelsService: DeviceModelsService, private router: Router) { }

  public ngOnInit(): void {
    this.statuses = [{value: null, title: 'All'}].concat(Object.values(DeviceModelGroupStatus).map(x => ({value: x, title: Utils.capitalize(x)})));
    this.updateData();
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData(false);
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public search(query: string): void {
    this.query = query;
    this.updateData();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Models Groups',
      text: this.information
    }});
  }

  public createModelsGroup(): void {
    this.router.navigate(['/reports', 'models-group', 'create']);
  }

  public viewDetails(modelsGroup: DeviceModelsGroup): void {
    this.router.navigate(['/reports', 'models-group', modelsGroup.id]);
  }

  public editModelsGroup(modelsGroup: DeviceModelsGroup): void {
    this.router.navigate(['/reports', 'models-group', modelsGroup.id, 'edit']);
  }

  public deleteModelsGroup(modelsGroup: DeviceModelsGroup): void {
    if (modelsGroup.deletable) {
      this.dialogService.showModal(ConfirmationDialogComponent, { data: {
        title: 'Delete Models Group',
        text: 'You are going to delete models group ' + modelsGroup.name + '. Are you sure?',
        icon: ConfirmDialogIcon.WARNING,
        agreeButtonText: 'Yes, delete'
      }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
        if (result) {
          this.deviceModelsService.deleteDeviceModelsGroup(modelsGroup).subscribe(() => {
            this.notificationService.success('Models Group successfully deleted');
            this.updateData(false);
          }, (error: HttpErrorResponse) => {
            this.notificationService.error('Failed to delete Models Group');
          });
        }
      });
    } else {
      this.dialogService.showModal(InformationDialogComponent, {width: '360px', data: {
        title: 'Can not delete group',
        text: 'It is not possible to delete this model group, because there are already linked system elements for it'
      }});
    }
  }

  public updateData(resetPage = true): void {
    this.loading = true;
    this.deviceModelsService.getDeviceModelsGroups(resetPage ? 1 : this.activePage, this.sorting, this.query, this.range, this.status?.value)
        .pipe(finalize(() => this.loading = false)).subscribe((modelsGroups: Paged<DeviceModelsGroup>) => {
      this.modelsGroups = modelsGroups;
      (this.modelsGroups.content || []).forEach((modelsGroup: DeviceModelsGroupView) => {
        modelsGroup.actions = [{
          title: 'View',
          action: () => this.viewDetails(modelsGroup)
        }, {
          title: 'Edit',
          action: () => this.editModelsGroup(modelsGroup),
          disabled: modelsGroup.status === DeviceModelGroupStatus.INACTIVE
        }, {
          title: 'Delete',
          action: () => this.deleteModelsGroup(modelsGroup),
        }];
      });
      this.activePage = resetPage ? 1 : this.activePage;
    }, (error: HttpErrorResponse) => {
      console.error(error);
    });
  }

  protected readonly TestReportStatus = TestReportStatus;
}

