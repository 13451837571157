<div class="test-statistics-view inner-view">
  <div class="view-header align-container space-between">
    <h1 class="align-container small-spacing">
      <span>Statistics</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section">
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="All">
        <perfect-scrollbar *ngIf="statistics">
          <div class="statistics-container">
            <div class="statistics-layout">
              <ng-container *ngFor="let row of statistics?.deviceTypeStatisticList; index as index">
                <div class="line-title">{{row.name || '&mdash;'}}</div>
                <div class="line-coverage" *ngIf="row.coverage">{{row.coverage | number:'0.2-2'}}%</div>
                <div class="line-coverage" *ngIf="!row.coverage">&mdash;</div>
                <div class="spacer" *ngIf="index % 3 === 0 || index % 3 === 1"></div>
              </ng-container>
            </div>
            <div class="overall-results">
              <div class="line-title">All models</div>
              <div class="line-coverage" *ngIf="statistics.commonCoverage">{{statistics.commonCoverage | number:'0.2-2'}}%</div>
              <div class="line-coverage" *ngIf="!statistics.commonCoverage">&mdash;</div>
            </div>
          </div>
        </perfect-scrollbar>
        <app-no-data *ngIf="!loading && !statistics"></app-no-data>
      </mat-tab>
    </mat-tab-group>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
  <div><b>Statistics</b> – section, which contains statistics on test coverage of smart devices supported in the evo application. The indicators are based on current (active) regression testing reports: if there is a report for a group of models, it is considered to be covered by tests.</div>
  <div class="less-space">This section presents test coverage rates for two types:</div>
  <ul class="less-space">
    <li>For the entire system (only one indicator)</li>
    <li>For each device type (multiple indicators)</li>
  </ul>
  <div class="less-space">Main conditions:</div>
  <ul class="less-space">
    <li>The test coverage rate is measured in percent (%). The number may contain a fractional part (two decimal place), rounding down.</li>
    <li>Only those models for which Device JSON-config and Model JSON-config are defined are considered in the calculation of test coverage percentage.</li>
  </ul>
  <div class="less-space">The list of test coverage rates separately for each device type contains information about:</div>
  <ol class="less-space">
    <li>Device type name</li>
    <li>Percentage of test coverage</li>
  </ol>
</ng-template>
