import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceModelsService } from '@services/device-models.service';
import { DialogService } from '@services/dialog.service';
import { UserInfoService } from '@services/user-info.service';
import { NotificationService } from '@services/notification.service';
import { InformationalService } from '@services/informational.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { Utils } from '@services/utils';
import { DeviceModel, DeviceModelTags, ModelStatus } from '@models/device-models';
import { Breadcrumbs } from '@models/breadcrumbs';
import { DropdownItem, LookupProvider } from '@models/dropdown';
import { Tag } from '@models/informational';
import { UserInfo } from '@models/user-info';
import { HttpParams } from '@constants/http-params';
import { DATE } from '@constants/dates';
import { Observable, map, finalize, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-device-model-view',
  templateUrl: './device-model-view.component.html',
  styleUrls: ['./device-model-view.component.scss']
})
export class DeviceModelViewComponent implements OnInit {

  public deviceModelId: number = null;
  public deviceModel: DeviceModel = null;
  public loading = false;
  public DATE_FORMAT = DATE;
  public tagsEditMode = false;
  public copyTagsModel: DropdownItem;
  public modelsSearchProvider: LookupProvider;
  public tagsSearchProvider: LookupProvider;
  public tags: Array<DropdownItem>;
  public approved = false;
  public breadcrumbs: Array<Breadcrumbs> = [{title: 'Device Models', link: ['/administration', 'device-models']}];
  public activeUser: Observable<UserInfo>;
  @ViewChild('information') information: TemplateRef<any>;

  constructor(private modelsService: DeviceModelsService, private dialogService: DialogService,
    private informationalService: InformationalService, private notificationService: NotificationService,
    private userInfoService: UserInfoService, private utils: Utils, private router: Router) { }

  public ngOnInit(): void {
    this.activeUser = this.userInfoService.getActiveUser();
    this.deviceModelId = Number(this.utils.lookupParam(HttpParams.MODEL_ID));
    if (this.deviceModelId !== null) {
      this.loading = true;
      this.modelsService.getDeviceModelById(this.deviceModelId).pipe(finalize(() => this.loading = false))
          .subscribe((model: DeviceModel) => {
        this.deviceModel = model;
        this.breadcrumbs.push({title: model.customerModel});
      });
    }
    this.modelsSearchProvider = {search: (query: string) => {
      return this.modelsService.lookupModelsTags(query).pipe(map((models: Array<DeviceModelTags>) => {
        return (models || []).map(x => ({value: x.id, title: x.modelName, additional: '' + (x.tags || []).length, original: x}));
      }));
    }};
    this.tagsSearchProvider = {search: (query: string) => {
      return this.modelsService.getModelsTags(query).pipe(map((tags : Array<Tag>) => {
        return (tags || []).map(x => ({value: x.id, title: x.name}));
      }));
    }};
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Model Card',
      text: this.information
    }});
  }

  public startEditTags(): void {
    this.copyTagsModel = null;
    this.tags = (this.deviceModel.tags || []).map(x => ({value: x.id, title: x.name}));
    this.tagsEditMode = true;
  }

  public copyTags(): void {
    if (this.copyTagsModel && this.tagsEditMode) {
      this.tags = (this.copyTagsModel.original.tags || []).map((x: Tag) => ({value: x.id, title: x.name}));
      this.copyTagsModel = null;
    }
  }

  public endEditTags(): void {
    if (this.tagsEditMode) {
      this.modelsService.applyModelTags(this.deviceModel.id, this.tags.map(x => ({id: x.value, name: x.title})))
          .subscribe((tags: Array<Tag>) => {
        this.deviceModel.tags = tags;
        this.tagsEditMode = false;
      }, (err: HttpErrorResponse) => {
        this.notificationService.error(err.message);
      });
    }
  }

  public cancelEditTags(): void {
    this.tagsEditMode = false;
  }

  public setApproved(): void {
    this.modelsService.setModelApproval(this.deviceModel.id, !!this.deviceModel.firmwares?.approved).subscribe(
      (updatedStatus: ModelStatus) => {
        this.deviceModel.status = updatedStatus;
        this.informationalService.updateGeneralInformation();
      },
      (err: HttpErrorResponse) => this.notificationService.error(err.message)
    );
  }

  public navigateInstructions(): void {
    this.navigateWithSecurityCheck(['/administration', 'instructions'], this.deviceModel.customerModel);
  }

  public navigateTestReports(): void {
    this.navigateWithSecurityCheck(this.deviceModel.testReport ? ['/reports', 'test-report', '' + this.deviceModel.testReport.id] : ['/reports', 'test-reports']);
  }

  public navigateFirmware(): void {
    this.navigateWithSecurityCheck(['/provisioning', 'firmware'], this.deviceModel.customerModel);
  }

  public navigateModelConfigs(): void {
    this.navigateWithSecurityCheck(['/administration', 'model-configs'],
      this.deviceModel.modelConfig ? this.deviceModel.customerModel : null);
  }

  public navigateJsonConfigs(): void {
    this.navigateWithSecurityCheck(['/administration', 'device-configs'],
      this.deviceModel.deviceConfig ? this.deviceModel.boardId : null);
  }

  private async navigateWithSecurityCheck(address: Array<string>, query?: string): Promise<void> {
    const activeUser = await lastValueFrom(this.activeUser);
    if (activeUser.isAdmin) {
      this.router.navigate(address, query ? {queryParams: {query}} : undefined);
    } else {
      this.userInfoService.showPermissionViolation();
    }
    return null;
  }

}
