<div class="models-group-view inner-view">
  <div class="view-header with-breadcrumbs">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <div class="align-container space-between">
      <h1 class="align-container small-spacing">
        <span>{{modelsGroup?.name || '&nbsp;'}}</span>
        <button class="information" (click)="showInfo()"></button>
      </h1>
      <div class="view-section controls">
        <button class="primary" (click)="editModelsGroup()" [disabled]="modelsGroup?.status === DeviceModelGroupStatus.INACTIVE">Edit</button>
        <button class="primary button-delete" (click)="deleteModelsGroup()">Delete</button>
      </div>
    </div>
  </div>
  <div class="view-section scroll-container" *ngIf="modelsGroup">
    <perfect-scrollbar>
      <div class="panel scroll-area contents-zone">
        <div class="info-line">
          <div class="label">Group name</div>
          <div class="value">{{modelsGroup?.name || '&mdash;'}}</div>
        </div>
        <div class="info-line">
          <div class="label">Device Type</div>
          <div class="value">{{modelsGroup?.deviceType?.name || '&mdash;'}}</div>
        </div>
        <div class="info-line">
          <div class="label">Status</div>
          <div class="value capitalize">{{modelsGroup?.status || '&mdash;'}}</div>
        </div>
        <div class="info-line">
          <div class="label">Created</div>
          <div class="value">{{modelsGroup.createdAt ? (modelsGroup.createdAt | date:DATE_TIME) : '&mdash;'}}</div>
        </div>
        <div class="info-line">
          <div class="label">Updated</div>
          <div class="value">{{modelsGroup.updatedAt ? (modelsGroup.updatedAt | date:DATE_TIME) : '&mdash;'}}</div>
        </div>
        <div class="info-line">
          <div class="label">Models</div>
          <div class="value">
            <div class="models-layout">
              <div class="model-link" *ngFor="let model of modelsGroup?.models">
                <a class="button link-button" [routerLink]="['/administration', 'device-models', model.id]">{{model.name || '&mdash;'}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <ngx-loading [show]="loading">
  </ngx-loading>
</div>
<ng-template #information>
  <div><b>Models Groups Card</b> – all information about the model group, including a list of the models that belong to it:</div>
  <ul class="top-space">
    <li><b>Group name</b> – model group name</li>
    <li><b>Device Type</b> – the model type within which the model group is contained. A model group can only be assigned to devices of the same type  (e.g. only to the Washing Machine type)</li>
    <li><b>Status</b> – models group status (Active/ Inactive). <br/><u>Note:</u> The status of a model group can only be changed to Inactive when adding models to an existing group, and if the old model group is to be replaced by a new one with a new set of models. In this case, the old model group goes to Inactive status</li>
    <li><b>Created</b> – model group creation date</li>
    <li><b>Updated</b> – model group update date</li>
    <li><b>Models</b> – list of models that are included in the model group. Each model in the list contains the model name and a link to the model card</li>
  </ul>
</ng-template>
