import { Injectable } from '@angular/core';
import { concatMap, Observable, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Utils } from './utils';
import { Paged } from '@models/pageable';
import { Sorting } from '@models/sorting';
import { DatesRange, fixRange } from '@models/dates';
import { FirmwareKind, FirmwareType } from '@models/firmware';
import { ExportType } from '@constants/export';
import { DeviceReportData, DeviceReportModels, DeviceReportTotals, FirmwareReportRow, TestReport, TestReportDetails,
  TestReportStatistics, TestReportStatus, TestReportType, TestReportTypeCode } from '@models/report';
import { RequestMessage, RequestAnswer, RequestQuestion, RequestQuestions, RequestMessages,
  RequestAnswers, RequestResult } from "@models/request";
import { DialogService } from "@services/dialog.service";
import { ConfirmationDialogComponent } from "@components/confirmation-dialog/confirmation-dialog.component";
import { ButtonsDialogComponent } from "@components/buttons-dialog/buttons-dialog.component";
import { ConfirmDialogIcon } from "@models/dialogs";
import {NotificationService} from "@services/notification.service";

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient, private utils: Utils, private dialogService: DialogService, private notificationService: NotificationService) {}

  public getDeviceReportTotals(): Observable<DeviceReportTotals> {
    return this.http.get<DeviceReportTotals>(`/device-service/api/v1/console/report`);
  }

  public getDeviceReportData(models: DeviceReportModels, datesRange: DatesRange, showModels: boolean): Observable<DeviceReportData> {
    const range = fixRange(datesRange);
    const params = {
      showModel: showModels,
      ...(range?.from ? {from: range.from.toISOString()} : null),
      ...(range?.to ? {to: range.to.toISOString()} : null)
    };
    return this.http.post<DeviceReportData>(`/device-service/api/v1/console/report`, models, {params});
  }

  public getTestReports(query: string, datesRange: DatesRange, type: TestReportTypeCode, status: TestReportStatus, page: number, sorting: Sorting): Observable<Paged<TestReport>> {
    const range = fixRange(datesRange);
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      ...(query ? {query} : null),
      ...(range?.from ? {from: range.from.toISOString()} : null),
      ...(range?.to ? {to: range.to.toISOString()} : null),
      ...(type ? {type} : null),
      ...(status ? {status} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null)
    };
    return this.http.get<Paged<TestReport>>(`/test-report-service/api/v1/console/test-report/pageable`, {params})
        .pipe(tap((paged: Paged<TestReport>) => {
      Utils.extendPaged(paged);
      paged.content.forEach((report: TestReport) => this.fixTestReport(report));
    }));
  }


  public getTestReportTypes(): Observable<Array<TestReportType>> {
    return this.http.get<Array<TestReportType>>(`/test-report-service/api/v1/console/test-report/type`);
  }

  public getIosVersions(): Observable<Array<string>> {
    return this.http.get<Array<string>>(`/test-report-service/api/v1/console/test-report/ios-versions`);
  }

  public getAndroidVersions(): Observable<Array<string>> {
    return this.http.get<Array<string>>(`/test-report-service/api/v1/console/test-report/android-versions`);
  }

  public getTestReportById(id: number): Observable<TestReportDetails> {
    return this.http.get<TestReportDetails>(`/test-report-service/api/v1/console/test-report/detailed/${id}`)
      .pipe(tap((testReport: TestReportDetails) => {
        this.fixTestReport(testReport)
        testReport.masterModel = (testReport.models || []).find(x => x.main)?.model;
        testReport.updatedAt = testReport.updatedAt ? new Date(testReport.updatedAt) : null;
        testReport.eppVersion = testReport.eppVersion ? Number(testReport.eppVersion) : null;
      }));
  }

  public createTestReport(file: File, testReport: TestReportDetails): Observable<number> {
    const data = new FormData();
    data.append('testReportFile', file, file.name);
    data.append('testReport', JSON.stringify(testReport));
    return this.http.put<number>(`/test-report-service/api/v1/console/test-report/create`, data);
  }

  public updateTestReport(testReport: TestReportDetails): Observable<number> {
    return this.http.put<number>(`/test-report-service/api/v1/console/test-report/edit`, testReport);
  }

  public downloadTestReport(testReportId: number, fileName: string): Observable<string> {
    return this.utils.downloadFileWithAuth(`/test-report-service/api/v1/console/test-report/download`, {id: testReportId});
  }

  public activateTestReport(reportId: number, answers: RequestAnswer): Observable<RequestQuestion | RequestMessage> {
    return this.http.put<RequestQuestion | RequestMessage>(`/test-report-service/api/v1/console/test-report/activate`, answers, {params: {reportId}});
  }

  public deactivateTestReport(reportId: number, answers: Array<RequestAnswer>): Observable<RequestQuestion | RequestMessage> {
    return this.http.put<RequestQuestion | RequestMessage>(`/test-report-service/api/v1/console/test-report/deactivate`, answers, {params: {reportId}});
  }

  public deleteTestReport(reportId: number, answers: Array<RequestAnswer>): Observable<RequestQuestion | RequestMessage> {
    return this.http.put<RequestQuestion | RequestMessage>(`/test-report-service/api/v1/console/test-report/delete`, answers, {params: {reportId}});
  }

  public activateTestReportProcedure(testReportId: number, testReportName: string, verbose = true): Observable<RequestResult> {
    return this.activateTestReport(testReportId, {question: RequestQuestions.WITHOUT_QUESTION, answer: RequestAnswers.YES})
      .pipe(concatMap(((result: RequestQuestion | RequestMessage) => {
        const res = result as RequestQuestion;
        if ((result as RequestMessage).resultMessage === RequestMessages.TARGET_TEST_REPORT_ACTIVATED) {
          verbose && this.notificationService.success(`Test Report ${testReportName} successfully activated`);
          return of({success: true, cancel: false});
        } else if ((res.question === RequestQuestions.ANOTHER_TEST_REPORT_EXISTS_DEACTIVATE_ANOTHER_TEST_REPORT)
            || (res.question === RequestQuestions.DEACTIVATE_ANOTHER_TEST_REPORT)) {
          const anotherReportName = res.params && res.params.length && res.params[0] || '-';
          const text = res.question === RequestQuestions.DEACTIVATE_ANOTHER_TEST_REPORT ?
            `For specified model group another active test report found${anotherReportName ? ' (' + anotherReportName + ')' : ''}. Deactivate previous test report?`
              : `For specified model group another active test report found${anotherReportName ? ' (' + anotherReportName + ')' : ''}, but list of logic configs are different. Deactivate previous test report?`;
          return this.dialogService.showModal(ConfirmationDialogComponent, { width: '500px', data: {
            title: 'Another Test Report exists',
            text,
            agreeButtonText: 'Yes, deactivate',
            cancelButtonText: 'No',
          }}).afterClosed().pipe(concatMap((dialogResult: boolean) => {
            if (dialogResult) {
              return this.activateTestReport(testReportId, {question: RequestQuestions.ANOTHER_TEST_REPORT_EXISTS_DEACTIVATE_ANOTHER_TEST_REPORT, answer: RequestAnswers.YES})
                .pipe(concatMap((result2: RequestQuestion | RequestMessage) => {
                  const success = (result2 as RequestMessage).resultMessage === RequestMessages.TARGET_TEST_REPORT_ACTIVATED_ANOTHER_DEACTIVATED;
                  if (verbose) {
                    success && this.notificationService.success(`Test Report ${testReportName} activated, ${anotherReportName} is no longer active`);
                    !success && this.notificationService.error(`Failed to activate ${testReportName}`);
                  }
                  return of({success, cancel: false});
                }));
            } else {
              verbose && this.notificationService.error(`Test Report ${testReportName} is not activated due to ${anotherReportName} is active`);
              return of({success: false, cancel: false, details: anotherReportName});
            }
          }));
        } else {
          verbose && this.notificationService.error(`Failed to activate ${testReportName}`);
          return of({success: false, cancel: false});
        }
      })));
  }

  public deactivateTestReportProcedure(testReport: TestReport, verbose = true): Observable<RequestResult> {
    return this.dialogService.showModal(ButtonsDialogComponent, { width: '500px', data: {
        title: 'Activate previous report',
        text: 'With deactivating of current report, would you like to set previous report active?',
        buttons: [{text: 'Cancel', class: 'secondary'}, {text: 'No', code: RequestAnswers.NO}, {text: 'Yes', code: RequestAnswers.YES}]
      }}).afterClosed().pipe(concatMap((dialogResult: string) => {
        if (dialogResult) {
          const answers = [{question: RequestQuestions.MOVE_LATEST_REPORT_TO_ACTIVE, answer: dialogResult as RequestAnswers}];
          return this.deactivateTestReport(testReport.id, answers).pipe(concatMap((deactivateResult: RequestQuestion | RequestMessage) => {
            const res = deactivateResult as RequestMessage;
            const prevReport = res.params && res.params.length && res.params[0] || '-';
            if (res.resultMessage === RequestMessages.REPORT_WAS_DEACTIVATED) {
              verbose && this.notificationService.success(`Test Report ${testReport.fileName || ''} successfully deactivated`);
              return of({success: true, cancel: false});
            } else if (res.resultMessage === RequestMessages.LAST_ACTIVE_REPORT_NOT_FOUND_REPORT_A_DEACTIVATED) {
              verbose && this.notificationService.success(`Test Report ${testReport.fileName || ''} successfully deactivated`);
              return of({success: true, cancel: false});
            } else if (res.resultMessage === RequestMessages.REPORT_A_WAS_DEACTIVATED_REPORT_B_WAS_ACTIVATED) {
              verbose && this.notificationService.success(`Test Report ${testReport.fileName || ''} successfully deactivated, ${prevReport} activated instead`);
              return of({success: true, cancel: false});
            } else if ((deactivateResult as RequestQuestion).question === RequestQuestions.JSON_LIST_NOT_EQUALS_DEACTIVATE_REPORT_A_AND_ACTIVATE_B) {
              return this.dialogService.showModal(ButtonsDialogComponent, { width: '500px', data: {
                  title: 'The data does not match',
                  text: `The data of previous discovered test report${prevReport ? ' (' + prevReport + ')' : ''} does not match in one or more of the parameters: Models Group, Type, Wi-Fi module, iOS version, Android version, EPP Json, Logic Json. Do you want to activate it anyway?`,
                  buttons: [{text: 'Cancel', class: 'secondary'}, {text: 'No', code: RequestAnswers.DEACTIVATE_REPORT_A}, {text: 'Yes', code: RequestAnswers.DEACTIVATE_REPORT_A_AND_ACTIVATE_B}]
                }}).afterClosed().pipe(concatMap((dialogResult2: string) => {
                  if (dialogResult2) {
                    answers.push({question: RequestQuestions.JSON_LIST_NOT_EQUALS_DEACTIVATE_REPORT_A_AND_ACTIVATE_B, answer: dialogResult2 as RequestAnswers});
                    return this.deactivateTestReport(testReport.id, answers).pipe(concatMap((deactivateResult2: RequestQuestion | RequestMessage) => {
                      const res2 = deactivateResult2 as RequestMessage;
                      if (res2.resultMessage === RequestMessages.REPORT_WAS_DEACTIVATED_REQUIRED_UPLOAD_NEW_REPORT && dialogResult2 === RequestAnswers.DEACTIVATE_REPORT_A) {
                        verbose && this.notificationService.success(`Test Report ${testReport.fileName || ''} successfully deactivated`);
                        return of({success: true, cancel: false});
                      } else if (res2.resultMessage === RequestMessages.REPORT_WAS_DEACTIVATED_LAST_ACTIVE_REPORT_ACTIVATED && dialogResult2 === RequestAnswers.DEACTIVATE_REPORT_A_AND_ACTIVATE_B) {
                        verbose && this.notificationService.success(`Test Report ${testReport.fileName || ''} successfully deactivated, ${prevReport} activated instead`);
                        return of({success: true, cancel: false, details: prevReport});
                      } else {
                        verbose && this.notificationService.error(`Failed to deactivate Test Report ${testReport.fileName}`);
                        return of({success: false, cancel: false});
                      }
                    }));
                  } else {
                    return of({success: false, cancel: true});
                  }
                }));
              } else {
                verbose && this.notificationService.error(`Failed to deactivate Test Report ${testReport.fileName}`);
                return of({success: false, cancel: false});
              }
          }));
        } else {
          return of({success: false, cancel: true});
        }
    }));
  }

  public deleteTestReportProcedure(testReport: TestReport, verbose = true): Observable<RequestResult> {
    return this.dialogService.showModal(ConfirmationDialogComponent, { data: {
      title: 'Delete Test Report',
      text: 'You are going to delete test report ' + testReport.fileName + '. Are you sure?',
      icon: ConfirmDialogIcon.WARNING,
      agreeButtonText: 'Yes, delete'
    }}).afterClosed().pipe(concatMap((upperConfirm: boolean) => {
      if (upperConfirm) {
        if (testReport.status === TestReportStatus.ACTIVE) {
          return this.dialogService.showModal(ButtonsDialogComponent, {
            width: '500px', data: {
              title: 'Activate previous report',
              text: 'With deleting of current report, would you like to set previous report for this group active?',
              buttons: [{text: 'Cancel', class: 'secondary'}, {text: 'No', code: RequestAnswers.NO}, {text: 'Yes', code: RequestAnswers.YES}]
            }
          }).afterClosed().pipe(concatMap((dialogResult: string) => {
            if (dialogResult) {
              const answers = [{
                question: RequestQuestions.MOVE_LATEST_REPORT_TO_ACTIVE,
                answer: dialogResult as RequestAnswers
              }];
              return this.deleteTestReport(testReport.id, answers).pipe(concatMap((deleteResult: RequestQuestion | RequestMessage) => {
                if ((deleteResult as RequestQuestion).question === RequestQuestions.LAST_ACTIVE_REPORT_NOT_FOUND_DELETE_REPORT_ANYWAY) {
                  return this.dialogService.showModal(ConfirmationDialogComponent, {
                    width: '500px', data: {
                      title: 'Previous report not found',
                      text: 'Previous report for this model group not found. Delete test report anyway?',
                    }
                  }).afterClosed().pipe(concatMap((confirmResult: boolean) => {
                    if (confirmResult) {
                      answers.push({
                        question: RequestQuestions.LAST_ACTIVE_REPORT_NOT_FOUND_DELETE_REPORT_ANYWAY,
                        answer: RequestAnswers.YES
                      });
                      return this.deleteTestReport(testReport.id, answers).pipe(concatMap((deleteResult2: RequestQuestion | RequestMessage) => {
                        return this.proceedDeletingProcedure(testReport, answers, deleteResult2, verbose);
                      }));
                    } else {
                      verbose && this.notificationService.error(`Failed to delete Test Report ${testReport.fileName}`);
                      return of({success: false, cancel: true});
                    }
                  }));
                } else {
                  return this.proceedDeletingProcedure(testReport, answers, deleteResult, verbose);
                }
              }));
            } else {
              return of({cancel: true, success: false});
            }
          }));
        } else {
          return this.deleteTestReport(testReport.id, [{
            question: RequestQuestions.WITHOUT_QUESTION,
            answer: RequestAnswers.YES
          }]).pipe(concatMap((result: RequestQuestion | RequestMessage) => {
            verbose && this.notificationService.success(`Test Report ${testReport.fileName} successfully deleted`);
            return of({
              cancel: false,
              success: (result as RequestMessage).resultMessage === RequestMessages.REPORT_WAS_DELETED
            });
          }));
        }
      } else {
        return of({cancel: true, success: false});
      }
    }));
  }

  public proceedDeletingProcedure(testReport: TestReport, answers: Array<RequestAnswer>, result: RequestQuestion | RequestMessage, verbose = true): Observable<RequestResult> {
    const res2 = result as RequestMessage;
    const prevReport = result.params && result.params.length && result.params[0] || '-';
    if (res2.resultMessage === RequestMessages.REPORT_WAS_DELETED) {
      verbose && this.notificationService.success(`Test Report ${testReport.fileName} successfully deleted`);
      return of({success: true, cancel: false});
    } else if (res2.resultMessage === RequestMessages.REPORT_A_WAS_DELETED_REPORT_B_WAS_ACTIVATED) {
      verbose && this.notificationService.success(`Test Report ${testReport.fileName} successfully deleted, ${prevReport} activated instead`);
      return of({success: true, cancel: false, details: prevReport});
    } else if ((result as RequestQuestion).question === RequestQuestions.JSON_LIST_NOT_EQUALS_DELETE_REPORT_A_AND_ACTIVATE_B) {
      return this.dialogService.showModal(ButtonsDialogComponent, { width: '500px', data: {
          title: 'The data does not match',
          text: 'The data of previous discovered test report does not match in one or more of the parameters: Models Group, Type, Wi-Fi module, iOS version, Android version, EPP Json, Logic Json. Do you want to activate it anyway?',
          buttons: [{text: 'Cancel', class: 'secondary'}, {text: 'No', code: RequestAnswers.DELETE_REPORT_A}, {text: 'Yes', code: RequestAnswers.DELETE_REPORT_A_AND_ACTIVATE_B}]
        }}).afterClosed().pipe(concatMap((dialogResult: string) => {
          if (dialogResult) {
            answers.push({
              question: RequestQuestions.JSON_LIST_NOT_EQUALS_DELETE_REPORT_A_AND_ACTIVATE_B,
              answer: dialogResult as RequestAnswers
            });
            return this.deleteTestReport(testReport.id, answers).pipe(concatMap((deleteResult: RequestQuestion | RequestMessage) => {
              const res2 = deleteResult as RequestMessage;
              if (res2.resultMessage === RequestMessages.REPORT_WAS_DELETED_REQUIRED_UPLOAD_NEW_REPORT && dialogResult === RequestAnswers.DELETE_REPORT_A) {
                verbose && this.notificationService.success(`Test Report ${testReport.fileName} successfully deleted`);
                return of({success: true, cancel: false});
              } else if (res2.resultMessage === RequestMessages.REPORT_WAS_DELETED_LAST_ACTIVE_REPORT_ACTIVATED && dialogResult === RequestAnswers.DELETE_REPORT_A_AND_ACTIVATE_B) {
                verbose && this.notificationService.success(`Test Report ${testReport.fileName} successfully deleted, ${prevReport} activated instead`);
                return of({success: true, cancel: false, details: prevReport});
              } else {
                verbose && this.notificationService.error(`Failed to delete Test Report ${testReport.fileName}`);
                return of({success: false, cancel: false});
              }
            }));
          } else {
            return of({cancel: true, success: false});
          }
        }));
      } else {
        verbose && this.notificationService.error(`Failed to delete Test Report ${testReport.fileName}`);
        return of({success: false, cancel: false});
      }
  }

  public loadTestReportStatistics(): Observable<TestReportStatistics> {
    return this.http.get<TestReportStatistics>(`/test-report-service/api/v1/console/test-report/stats`);
  }

  public exportDevicesReport(models: DeviceReportModels, datesRange: DatesRange, showModels: boolean,
      sorting: Sorting, exportType: ExportType): Observable<string> {
    const range = fixRange(datesRange);
    const params = {
      format: exportType,
      showModel: showModels,
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null),
      ...(range?.from ? {from: range.from.toISOString()} : null),
      ...(range?.to ? {to: range.to.toISOString()} : null)
    };
    return this.utils.downloadFileWithAuth(`/device-service/api/v1/console/report/export`, params, models);
  }

  public getFirmwareReportData(query: string, deviceType: number, kind: FirmwareKind, type: FirmwareType,
      activeOnly: boolean, page: number, sorting: Sorting): Observable<Paged<FirmwareReportRow>> {
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      active: activeOnly,
      ...(query ? {query} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null),
      ...(deviceType ? {deviceType} : null),
      ...(kind ? {kind} : null),
      ...(type ? {type} : null)
    };
    return this.http.get<Paged<FirmwareReportRow>>(`/device-firmware-service/api/v1/console/firmware/reports`, {params})
        .pipe(tap((paged: Paged<FirmwareReportRow>) => {
      Utils.extendPaged(paged);
      paged.content.forEach(row => this.fixDates(row));
    }));
  }

  public exportFirmwareReport(query: string, deviceType: number, kind: FirmwareKind, type: FirmwareType,
      activeOnly: boolean, sorting: Sorting, exportType: ExportType): Observable<string> {
    const params = {
      format: exportType,
      ...(query ? {query} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null),
      ...(deviceType ? {deviceType} : null),
      ...(kind ? {kind} : null),
      ...(type ? {type} : null),
      ...(activeOnly ? {activeOnly} : null),
    };
    return this.utils.downloadFileWithAuth(`/device-firmware-service/api/v1/console/firmware/reports/export`, params);
  }

  private fixDates(row :FirmwareReportRow): FirmwareReportRow {
    row.createdAt = row.createdAt ? new Date(row.createdAt) : null;
    row.lastInstalledAt = row.lastInstalledAt ? new Date(row.lastInstalledAt) : null;
    return row;
  }

  private fixTestReport(report: TestReport): TestReport {
    report.createdAt = report.createdAt ? new Date(report.createdAt) : null;
    return report;
  }

}
