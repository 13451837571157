import { Base } from '@models/base';
import { Action } from '@models/actions';
import { Tag, TagView, BriefInfo } from '@models/informational';
import { FirmwareInfo } from '@models/firmware';

export interface ProductLine extends Base {
}

export interface DeviceType extends Base {
  updatedAt: Date;
  productLines: Array<ProductLine>;
}

export interface DeviceTypeView extends DeviceType {
  actions?: Array<Action>;
}

export enum ModelStatus {
  COMPLETED = 'COMPLETED',
  UNCOMPLETED = 'UNCOMPLETED'
}

export interface DeviceModel {
  id: number;
  materialNumber: string;
  customerModel: string;
  boardId: string;
  deviceTypeId: number;
  deviceTypeName: string;
  productLineId: number;
  updatedAt: Date;
  status: ModelStatus;
  tags: Array<Tag>;
  deviceConfig: BriefInfo;
  modelConfig: BriefInfo;
  firmwares: FirmwareInfo;
  instruction: BriefInfo;
  testReport: BriefInfo;
}

export interface DeviceModelTags {
  id: number;
  modelName: string;
  tags: Array<Tag>;
}

export interface DeviceModelView extends DeviceModel {
  tags: Array<TagView>;
}

export interface DeviceModelNode {
  object: DeviceModelSimple;
  checked: boolean;
  hidden?: boolean;
  parent: DeviceTypeNode;
}

export interface DeviceTypeNode {
  object: DeviceTypeSimple;
  checked: boolean | null;
  hidden?: boolean;
  collapsed: boolean;
  children: Array<DeviceModelNode>;
  selectedCount?: number;
}

export interface DeviceTypeSimple extends Base {
  productLines: Array<ProductLine>;
}

export interface DeviceModelSimple extends Base {
  typeId?: number;
  tags?: Array<Tag>;
}

export interface DeviceSmartModelSimple {
  id: number;
  customerModel: string;
  deviceTypeId: number;
  identificationCodes: string[];
}

export interface DeviceTypesAndModels {
  types: Array<DeviceTypeSimple>;
  models: Array<DeviceModelSimple>;
}

export interface DeviceModelUniquinessResponse {
  customerModel: boolean;
  materialNumber: boolean;
}

export enum DeviceModelGroupStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum GroupingType {
  NONE = 'NONE',
  EPP = 'EPP',
}

export interface DeviceModelsGroup {
  id: number | null;
  name: string;
  deviceType: Base;
  groupingType: GroupingType;
  identificationCode?: string;
  createdAt?: Date;
  updatedAt?: Date;
  status: DeviceModelGroupStatus;
  deletable?: boolean;
}

export interface DeviceModelGroupEpp {
  identificationCode: string;
  deviceTypeId: number;
}

export interface DeviceModelsGroupView extends DeviceModelsGroup {
  actions?: Array<Action>;
}

export interface DeviceModelsGroupDetails extends DeviceModelsGroup {
  models: Array<Base>;
  editLimited?: boolean; // connected objects such as test reports exists
}

export interface DeviceModelGroups {
  model: Base;
  groupList: Array<Base>;
}

export interface DeviceModelsGroupConfig extends Base {
  latest: boolean;
}

export interface DeviceModelsGroupModelConfig {
  model: Base;
  configs: Array<DeviceModelsGroupConfig>;
}

export interface DeviceModelsGroupConfigs extends DeviceModelsGroup {
  modelsConfig: Array<DeviceModelsGroupModelConfig>;
}
