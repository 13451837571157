import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceModelGroups, DeviceModelGroupStatus, DeviceModelsGroupDetails, DeviceTypesAndModels, GroupingType } from '@models/device-models';
import { Base } from '@models/base';
import { RequestResult } from "@models/request";
import { DeviceModelsService } from '@services/device-models.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { Utils } from '@services/utils';
import { Breadcrumbs } from '@models/breadcrumbs';
import { DropdownItem, LookupProvider } from '@models/dropdown';
import { HttpParams } from '@constants/http-params';
import { finalize, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { DeviceConfigsService } from "@services/device-configs.service";

interface ModelRow {
  model: DropdownItem;
  items?: Array<DropdownItem>;
  provider?: LookupProvider;
  groups?: Array<Base>;
  disabled?: boolean;
}

@Component({
  selector: 'app-models-group-edit-view',
  templateUrl: './models-group-edit-view.component.html',
  styleUrls: ['./models-group-edit-view.component.scss']
})
export class ModelsGroupEditViewComponent implements OnInit, OnDestroy {

  public modelsGroupId: number;
  public modelsGroup: DeviceModelsGroupDetails;
  public loading = false;
  public breadcrumbs: Array<Breadcrumbs> = [{title: 'Models Groups', link: ['/reports', 'models-groups']}];
  public groupName: string = '';
  public deviceType: DropdownItem;
  public deviceTypes: Array<DropdownItem> = [];
  public groupingTypes: Array<DropdownItem> = [{value: GroupingType.NONE, title: 'None'}, {value: GroupingType.EPP, title: 'Epp'}];
  public groupingType: DropdownItem = this.groupingTypes[0];
  public allGroupingEpps: Array<DropdownItem> = [];
  public groupingEpp: DropdownItem;
  public rows: Array<ModelRow> = [];
  public allModels: Array<DropdownItem> = [];
  public newModel: DropdownItem;
  public availableNewModels: Array<DropdownItem> = [];
  public newModelProvider: LookupProvider = {
    search: (query: string) => of(this.availableNewModels.filter(x => x.title.toLowerCase().includes(query.toLowerCase())))
  };
  public groupingEppProvider: LookupProvider = {
    search: (query: string) => {
      const groupingEpps = (this.allGroupingEpps || []).filter(x => x.original.deviceTypeId === this.deviceType?.value);
      return of(groupingEpps.filter(x => x.title.toLowerCase().includes(query.toLowerCase())));
    }
  };
  public errors: any;
  public DeviceModelGroupStatus = DeviceModelGroupStatus;
  public GroupingType = GroupingType;

  @ViewChild('information') information: TemplateRef<any>;
  @ViewChild('scrollbar') private scrollbar: PerfectScrollbarComponent;

  private destroyed = new Subject<void>();
  private returnData: any;

  constructor(private deviceModelsService: DeviceModelsService, private deviceConfigsService: DeviceConfigsService,
      private dialogService: DialogService, private router: Router, private notificationService: NotificationService,
      private utils: Utils, private changeDetectorRef: ChangeDetectorRef) {
    this.returnData = this.router.getCurrentNavigation().extras.state;
  }

  public ngOnInit(): void {
    this.loading = true;
    this.modelsGroupId = Number(this.utils.lookupParam(HttpParams.MODELS_GROUP_ID));
    forkJoin({
      deviceTypes: this.deviceModelsService.getDeviceTypes(),
      smartModels: this.deviceModelsService.getSmartDeviceModels(),
      groupingEpps: this.deviceConfigsService.getEppIcs(),
      modelsGroup: this.modelsGroupId ? this.deviceModelsService.getDeviceModelsGroup(this.modelsGroupId) : of(null)
    }).pipe(takeUntil(this.destroyed), finalize(() => this.loading = false)).subscribe(({deviceTypes, smartModels, groupingEpps, modelsGroup}) => {
      this.modelsGroup = modelsGroup;
      this.allGroupingEpps = groupingEpps.map(x => ({value: x.identificationCode, title: x.identificationCode, original: x}));
      this.breadcrumbs.push({title: modelsGroup?.name || 'New Models Group'});
      this.deviceTypes = (deviceTypes || []).map(x => ({value: x.id, title: x.name, original: x}));
      this.allModels = (smartModels || []).map(x => ({value: x.id, title: x.customerModel, original: x}));
      if (modelsGroup) {
        this.groupName = modelsGroup.name || '';
        this.deviceType = (this.deviceTypes || []).find(x => x.value === modelsGroup.deviceType.id);
        this.groupingType = this.groupingTypes.find(x => x.value === modelsGroup.groupingType);
        this.groupingEpp = this.allGroupingEpps.find(x => x.value === modelsGroup.identificationCode);
        this.rows = (modelsGroup.models || []).map(x => ({model: this.allModels.find(y => y.value === x.id), disabled: modelsGroup.editLimited}));
        this.checkRows();
      }
      this.fillAvailables();
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Models Group Card',
      text: this.information
    }});
  }

  public showPopover(popover: NgbPopover, row: ModelRow): void {
    popover.open({row});
  }

  public hidePopover(popover: NgbPopover): void {
    popover.close();
  }

  public deviceTypeUpdated(deviceType: DropdownItem): void {
    const groupingEpps = this.allGroupingEpps.filter(x => x.original.deviceTypeId === deviceType.value);
    if (!groupingEpps.find(x => x === this.groupingEpp)) {
      this.groupingEpp = null;
    }
    this.fillAvailables();
  }

  public groupingTypeUpdated(groupingType: DropdownItem): void {
    if (groupingType.value === GroupingType.NONE) {
      this.groupingEpp = null;
    }
    this.fillAvailables();
  }

  public checkRows(): void {
    const modelsIds = this.rows.map(x => x.model.value);
    this.deviceModelsService.getSpecifiedDeviceModelsGroups(modelsIds)
        .pipe(takeUntil(this.destroyed)).subscribe((response: Array<DeviceModelGroups>) => {
      this.rows.forEach((row) => row.groups = []);
      (response || []).forEach((modelGroups: DeviceModelGroups) => {
        const row = this.rows.find(x => x.model.value === modelGroups.model.id);
        row && (row.groups = (modelGroups.groupList || []).filter(x => x.id !== this.modelsGroupId));
      });
    });
  }

  public addModel(): void {
    const newRow = {model: this.newModel};
    this.rows.push(newRow);
    setTimeout((): void => this.newModel = null);
    this.fillAvailables();
    this.checkRows();
    this.changeDetectorRef.detectChanges();
    this.scrollToBottom();
  }

  public removeRow(row: ModelRow): void {
    this.rows = this.rows.filter(x => x !== row);
    this.fillAvailables();
  }

  public clearRows(): void {
    this.rows = [];
    this.availableNewModels = [];
    this.newModel = null;
  }

  public fillAvailables(): void {
    if (this.deviceType && this.groupingType && (this.groupingType.value === GroupingType.NONE || this.groupingEpp)) {
      const deviceTypeModels = this.allModels.filter(x => x.original.deviceTypeId === this.deviceType.value);
      const groupingModels = deviceTypeModels.filter(x => {
        return this.groupingType.value === GroupingType.NONE || (x.original.identificationCodes || []).includes(this.groupingEpp.value);
      });
      this.rows = this.rows.filter(x => groupingModels.includes(x.model));
      const sort = (x: DropdownItem, y: DropdownItem) => (x.title || '').localeCompare(y.title);
      const models = this.rows.map(x => x.model);
      this.availableNewModels = groupingModels.filter(x => !models.includes(x)).sort(sort);
      for (let i = 0; i < models.length; i++) {
        this.rows[i].items = [...this.availableNewModels].concat(models[i]).sort(sort);
        this.rows[i].provider = {
          search: (query: string) =>
            of(this.rows[i].items.filter(x => x.title.toLowerCase().includes(query.toLowerCase())))
        };
      }
    } else {
      this.clearRows();
    }
  }

  public scrollToBottom(): void {
    this.scrollbar?.directiveRef?.scrollToBottom();
  }

  public validate(): boolean {
    this.errors = {};
    if (!this.groupName) {
      this.errors = {groupName: {required: true}};
    } else if (!/^[A-Za-z0-9а-яА-Я_\-\s]{1,255}$/.test(this.groupName) || this.groupName.startsWith(' ') || this.groupName.endsWith(' ')) {
      this.errors = {groupName: {invalid: true}};
    }
    if (!this.deviceType) {
      Object.assign(this.errors, {deviceType: {required: true}});
    }
    if (this.groupingType.value === GroupingType.EPP && !this.groupingEpp) {
      Object.assign(this.errors, {groupingEpp: {required: true}});
    }
    if (!this.rows.length) {
      Object.assign(this.errors, {models: {required: true}});
    }
    if (!Object.keys(this.errors).length) {
      this.errors = null;
    }
    return !this.errors;
  }

  public resetValidation(): void {
    this.errors = null;
  }

  public save(): void {
    if (this.validate()) {
      if (this.rows.some(x => x.groups?.length)) {
        this.dialogService.showModal(ConfirmationDialogComponent, { width: '520px', data: {
          title: 'Confirm models selection',
          text: 'Some models added to this model group already included to different model groups. Confirm you want to proceed with these models.',
          agreeButtonText: 'Yes, proceed'
        }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
          if (result) {
            this.proceedSave();
          }
        });
      } else {
        this.proceedSave();
      }
    }
  }

  public cancel(): void {
    this.router.navigate(this.returnData ? ['/' + this.returnData.path] : ['/reports', 'models-groups']);
  }

  private proceedSave(): void {
    const modelsGroup = {
      id: this.modelsGroupId,
      name: this.groupName,
      status: this.modelsGroup ? this.modelsGroup.status : DeviceModelGroupStatus.ACTIVE,
      groupingType: this.groupingType.value,
      identificationCode: this.groupingEpp?.value,
      deviceType: {id: this.deviceType.value, name: this.deviceType.title},
      models: this.rows.map(x => ({id: x.model.value, name: x.model.title}))
    };
    if (this.modelsGroupId) {
      this.deviceModelsService.updateDeviceModelsGroupProcedure(modelsGroup).pipe(takeUntil(this.destroyed)).subscribe((result: RequestResult) => {
        if (result.cancel) {
          return;
        }
        if (result.success) {
          this.notificationService.success(`Models group ${this.groupName || ''} successfully updated`);
          this.cancel();
        } else {
          this.notificationService.error(`Models group ${this.groupName || ''} is not saved`);
        }
      }, () => {
        this.notificationService.error(`Failed to update Models group ${this.groupName || ''}`);
      });
    } else {
      this.deviceModelsService.createDeviceModelsGroup(modelsGroup)
        .pipe(takeUntil(this.destroyed), finalize(() => this.loading = false)).subscribe(() => {
        this.notificationService.success(`Models group ${this.groupName || ''} successfully created`);
        this.cancel();
      }, () => {
        this.notificationService.error(`Failed to create Models group ${this.groupName || ''}`);
      });
    }
  }

}
